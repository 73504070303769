import * as React from "react"
import './src/components/global.css'

// Logs when the client route changes
export function onRouteUpdate({ location, prevLocation }) {
    if (location.hash === "") window.scrollTo({top: 0, behavior: "instant"}); // This condition excludes anchor navigation 
    // console.log("new pathname", location.pathname)
    // console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}

export function onInitialClientRender() {
    window.scrollTo(0, 0);
};

export function shouldUpdateScroll() { return [0, 0] }

